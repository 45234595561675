<template>
    <div class="latest-news-area pt-120 pb-90" id="news" style="background-color: #f1f1f1;">
        <div class="container">
            <div class="row mb-60">
                <div class="col-12 swipyMcSwipyface">
                    <div class="sec-wrapper">
                        <h5>Featured News</h5>
                        <h2 class="section-title">Latest News From Chapter 375</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <swiper ref="mySwiper" class="pb-50 swipyMcSwipyface" :space-between="25" :slides-per-view="4" :modules="modules"
                    :pagination="{ clickable: true }" :loop="false" :breakpoints="breakpoints">
                    <swiper-slide v-for="post in blogPosts" :key="post.id" class="col-xl-4 col-lg-4 col-md-6">
                        <div class="latest-blog latest-blog-2">
                            <div class="latest-blog-img pos-rel">
                                <img :src="setUrl(post.attributes.photos.data.attributes.url)" alt="">
                                <div class="top-date">
                                    <a href="#">{{ normalizeDateTime(post.attributes.date) }}</a>
                                </div>
                            </div>
                            <div class="latest-blog-content latest-blog-content-2">
                                <div class="latest-post-meta mb-15">
                                    <span><a href="#"><i class="far fa-user"></i> {{ post.attributes.name }} </a></span>
                                    <!-- <span><a href="#"><i class="far fa-comments"></i> {{post.attributes.comments.length}}</a></span> -->
                                </div>
                                <h3 class="latest-blog-title">
                                    <router-link to="/blog-details" @click="handleClick(post.id)">{{
                    post.attributes.title }}</router-link>
                                </h3>
                                <div class="blog-btn-2">
                                    <router-link to="/blog-details" @click="handleClick(post.id)"
                                        class="link-btn latest-blog-name">
                                        read more
                                        <i class="fal fa-long-arrow-right"></i>
                                        <i class="fal fa-long-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination } from "swiper"
import { ref, defineProps, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
    blogPosts: {
        type: Array,
        required: true
    }
})

const initialized = ref(false)
const blogData = ref([])
// const localEnv = process.env.VUE_APP_environment
const isProduction = process.env.VUE_APP_environment === 'production'
const urlBaseUploads = isProduction ? process.env.VUE_APP_strapiServerProd : process.env.VUE_APP_strapiServerLocal

const normalizeDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString)
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }

    return date.toLocaleDateString(undefined, options)
}

const handleClick = (articleId) => {
    store.commit('setSelectedArticle', articleId)
}

watch(() => props.blogPosts, (newPosts) => {
    if (!initialized.value) {
        const sorted = newPosts.sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date))
        blogData.value = sorted
        initialized.value = true
    }
}, { deep: true })

const setUrl = (url) => {
    const fixedUrl = urlBaseUploads + url
    return `${fixedUrl}`

}

const breakpoints = {
    300: { slidesPerView: 1 },
    550: { slidesPerView: 2 },
    768: { slidesPerView: 3 },
    992: { slidesPerView: 4 },
}

const modules = [Pagination]

</script>

<style >
.swipyMcSwipyface{
    display: flex;  
    justify-content: center;
}
.swiper-wrapper{
    display: flex;
    justify-content: center;
}
</style>