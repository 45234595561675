<template>
    <Header />
    <HomeTwoSlider />
    <!-- <HomeTwoServices/> -->
    <HomeTwoAbout :about="about" />
    <HomeTwoFaq />
    <Team :team="team" />
    <Partners />
    <!-- <HomeTwoPortfolio/> -->
    <CalendarArea :schedule="chapterSchedule" />
    <HomeTwoBlog :blogPosts="blogPosts" />
    <Cta />
    <Pricing />
    <ContactMap />
    <HomeTwoFooter />
</template>

<script setup>
import axios from 'axios'
import { ref, onMounted } from 'vue'
import Header from '../../components/Home-two/HomeTwoHeader.vue'
import HomeTwoSlider from '../../components/Home-two/HomeTwoSlider.vue'
import CalendarArea from '../../components/Calendar/CalendarArea.vue'
// import HomeTwoServices from '../../components/Home-two/HomeTwoServices.vue'
import HomeTwoAbout from '../../components/Home-two/HomeTwoAbout.vue'
import Pricing from '@/components/Pricing/PricingArea.vue'
import HomeTwoFaq from '../../components/Home-two/HomeTwoFaq.vue'
import Team from '../../components/Home/Team.vue'
import Partners from '../../components/Home-two/Partners.vue'
// import HomeTwoPortfolio from '../../components/Home-two/HomeTwoPortfolio.vue'
import Cta from '../../components/Home/Cta.vue'
import HomeTwoBlog from '../../components/Home-two/HomeTwoBlog.vue'
import ContactMap from '../../components/Contact/ContactMap.vue'
import HomeTwoFooter from '../../components/Home-two/HomeTwoFooter.vue'
import { useStore } from 'vuex'

const blogPosts = ref([])
const about = ref([])
const chapterSchedule = ref([])
const team = ref([])

onMounted(async () => {
    const store = useStore()

    try {
        const isProduction = process.env.VUE_APP_environment === 'production'
        const urlBase = isProduction ? process.env.VUE_APP_strapiServerProdApi : process.env.VUE_APP_strapiServerLocalApi
        console.log(isProduction)
        console.log(urlBase)

        const blogPostsData = await axios.get(`${urlBase}blog-posts?populate=photos`)
        const aboutData = await axios.get(`${urlBase}about-sections`)
        const schedule = await axios.get(`${urlBase}events`)
        const teamMembers = await axios.get(`${urlBase}team-leaderships?populate=*`)
        const partners = await axios.get(`${urlBase}partners?populate=*`)

        store.commit('setPartners', partners.data.data)
        store.commit('setBlogPosts', blogPostsData.data.data)
        store.commit('setTeam', teamMembers.data.data)

        blogPosts.value = Object.values(blogPostsData.data.data)
        about.value = Object.values(aboutData.data.data)
        chapterSchedule.value = Object.values(schedule.data.data)
        team.value = Object.values(teamMembers.data.data)

    } catch (error) {
        console.error(error)
    }
})
</script>
