<template>
   <section class="team__details pt-120 pb-160">
      <div class="container">
         <div class="team__details-inner p-relative white-bg">
            <div class="row">
               <div class="col-xl-6 col-lg-6">
                  <div class="team__details-img w-img mr-70">
                     <img :src="getUrl(member.attributes.image.data.attributes.url)" alt="" />
                  </div>
               </div>
               <div class="col-xl-6 col-lg-6">
                  <div class="team__details-content pt-105">
                     <span>{{ member.attributes.title }}</span>
                     <h3>{{ member.attributes.name }}</h3>
                     <h6>{{ member.attributes.conflict }}</h6>
                     <br>
                     <div class="team__details-contact mb-45">
                        <ul>
                           <li>
                              <div class="icon theme-color ">
                                 <i class="fal fa-envelope"></i>
                              </div>
                              <div class="text theme-color ">
                                 <span><a>{{ member.attributes.email }}</a></span>
                              </div>
                           </li>
                           <li>
                              <div class="icon theme-color">
                                 <i class="fas fa-phone-volume"></i>
                              </div>
                              <div class="text theme-color">
                                 <span><a >{{ member.attributes.phoneNumber }}</a></span>
                              </div>
                           </li>
                           <li>
                              <div class="icon">
                                 <i class="fal fa-map-marker-alt"></i>
                              </div>
                              <div class="text">
                                 <a target="_blank" href="https://maps.app.goo.gl/7udqqzxCAQtGQqbL6">{{
                        member.attributes.address }}</a>
                              </div>
                           </li>
                        </ul>
                     </div>
                     <div class="team__details-social theme-social wow fadeInUp">
                        <ul>
                           <li>
                              <a :href="member.attributes.linkedin" target="_blank">
                                 <i class="fab fa-linkedin"></i>
                              </a>
                              <a :href="member.attributes.facebook" target="_blank">
                                 <i class="fab fa-facebook-f"></i>
                              </a>
                              <a :href="member.attributes.twitter" target="_blank">
                                 <i class="fab fa-twitter"></i>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-xl-10 offset-xl-1">
               <div class="team__details-info mt-60">
                  <h4>About</h4>
                  <p v-for="(text, index) in member.attributes.biography" :key="index">
                     {{ text.children[0].text }}
                  </p>


               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const isProduction = process.env.VUE_APP_environment === 'production'
const urlBaseUploads = isProduction ? process.env.VUE_APP_strapiServerProd : process.env.VUE_APP_strapiServerLocal

const route = useRoute()
const memberId = route.params.id
const team = computed(() => store.state.team)
const member= computed(() => team.value.find(mem => mem.id.toString() === memberId.toString())).value

const getUrl = (photUrl) => {
   return urlBaseUploads + photUrl
}

</script>