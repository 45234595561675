<template>
   <div class="faq-area pos-rel black-bg">
      <div class="faq-bg" :style="{ backgroundImage: 'url(' + require('../../assets/img/bg/ceremony.png') + ')' }">
      </div>
      <div class="container">
         <div class="row">
            <div class="col-xl-6 offset-xl-6 col-lg-6 offset-lg-6">
               <div class="faq-content pl-70 pt-120 pb-120">
                  <div class="sec-wrapper mb-30">
                     <h5>Military Order of the Purple Heart Chapter 375</h5>
                     <h2 class="section-title text-white">FAQ</h2>
                  </div>
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              What is the history of the purple heart?
                           </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>
                                 The Purple Heart traces its origins back to the Badge of Military Merit established by
                                 General George Washington in 1782, originally awarded for acts of bravery during the
                                 Revolutionary War. Revived in 1932 as the Purple Heart, its scope expanded to honor
                                 those wounded or killed while serving in the U.S. Armed Forces.</p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              How do I donate to Chpater 375?
                           </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>You can donate to our chapter using this <a class="link" href="#pricing">link!</a> Your donation will
                                 go to helping
                                 combat wounded veterans in the Denver area and serving the community through events
                                 that our chapter participates in. </p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree1">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                              How will the money I donate be spent?
                           </button>
                        </h2>
                        <div id="collapseThree1" class="accordion-collapse collapse" aria-labelledby="headingThree1"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>Every cent of your money will go towards helping local veterans of all kinds and to community outreach events that we take part in such as local JROTC programs where we present awards to students.</p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              How Many chapters of MOPH are in Colorado?
                           </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>The chapters in Colorado are <a class="link" href="https://moph423.org/"
                                    target="_blank">423</a>, 1041 and 375. Chapter 423 is the largest in Colorado with
                                 over 300 members spanning from WWII to OIF/OEF. </p>
                              <br>
                              <p>There is also the <a class="link" href="http://www.mophcolorado.org/"
                                    target="_blank">Department of
                                    Colorado</a> which oversees all chapters in the state.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'HomeTwoFaq'
}
</script>